<template>
  <div id="departments">
    <div class="subheader pb-2 pb-lg-4 subheader-transparent" id="kt_subheader">
      <div
        class="
          container-fluid
          d-flex
          align-items-center
          justify-content-between
          flex-wrap flex-sm-nowrap
        "
      >
        <!--begin::Details-->
        <div class="d-flex align-items-center flex-wrap mr-2">
          <!--begin::Title-->
          <h5
            class="text-dark font-weight-bold mt-2 mb-2 mr-5"
            v-if="!byDepartment"
          >
            {{ $t("menu.controller.departments.title") }}
          </h5>
          <!--end::Title-->
          <!--begin::Separator-->
          <div
            class="
              subheader-separator subheader-separator-ver
              mt-2
              mb-2
              mr-5
              bg-gray-200
            "
          ></div>
          <!--end::Separator-->
          <!--begin::Search Form-->
          <div class="d-flex align-items-center" id="kt_subheader_search">
            <span class="text-dark-50 font-weight-bold" id="kt_subheader_total"
              >{{ total }}
              {{
                $t("tables.total", {
                  module: $tc("tables.department", total).toLowerCase()
                })
              }}</span
            >
            <div class="ml-5">
              <div
                class="input-group input-group-sm input-group-solid"
                style="max-width: 175px"
              >
                <input
                  type="text"
                  class="form-control"
                  id="search"
                  v-model="search"
                  :placeholder="$t('tables.search') + ' ...'"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <span class="svg-icon">
                      <!--begin::Svg Icon | path:/metronic/theme/html/demo9/dist/assets/media/svg/icons/General/Search.svg-->
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M14.293 16.707a1 1 0 011.414-1.414l4 4a1 1 0 01-1.414 1.414l-4-4z" fill="#000" fill-rule="nonzero" opacity=".3"/><path d="M11 16a5 5 0 100-10 5 5 0 000 10zm0 2a7 7 0 110-14 7 7 0 010 14z" fill="#000" fill-rule="nonzero"/></g></svg>
                      <!--end::Svg Icon-->
                    </span>
                    <!--<i class="flaticon2-search-1 icon-sm"></i>-->
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!--end::Search Form-->

          <b-button
            @click="updateMultiDepartment()"
            variant="light-success"
            class="ml-2 font-weight-bold"
            :disabled="selected.length == 0 && total == 0"
          >
            <i class="la la-pencil pl-1" />
            {{
              $t("assets.buttons.update_departments", {
                departments: selected.length == 0 ? total : selected.length
              })
            }}
          </b-button>
          <b-button
            @click="deleteMultiDepartment()"
            variant="light-danger"
            class="ml-2 font-weight-bold"
            :disabled="selected.length == 0 && total == 0"
          >
            <i class="la la-trash pl-1" />
            {{
              $t("assets.buttons.delete_departments", {
                departments: selected.length == 0 ? total : selected.length
              })
            }}
          </b-button>
        </div>
        <!--end::Details-->
        <!--begin::Toolbar-->
        <div class="d-flex align-items-center">
          <json-excel
            :data="filtered"
            :fields="excelFields"
            v-b-tooltip.left.hover
            :title="$t('tables.download')"
            type="xls"
            name="departments"
            class="btn btn-light-primary btn-icon ml-3"
          >
            <span class="svg-icon svg-icon-light-primary px-2">
              <simple-svg
                :src="require('@/assets/images/icons/downloaded-file.svg')"
              />
            </span>
          </json-excel>
          <b-button
            @click="$router.push({ name: 'DepartmentCreate' })"
            variant="light-success"
            class="ml-2 font-weight-bold"
            v-b-tooltip.left.hover
            :title="$t('tables.create.department')"
          >
            <i class="p-0 fa fa-plus" />
          </b-button>
          <!--end::Button-->
          <input
            name="excelFile"
            ref="excelFile"
            id="excelFile"
            style="display:none;"
            type="file"
            accept=".xls,.xlsx"
            @change="handleFileExcel"
          />
          <label
            v-if="hasExcelPermissions"
            style="margin-bottom: 0px;"
            :title="$t('admin.excel.title.import_excel')"
            for="excelFile"
            class="btn ml-2 font-weight-bold btn-light-success"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-filetype-xls"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM6.472 15.29a1.2 1.2 0 0 1-.111-.449h.765a.58.58 0 0 0 .254.384q.106.073.25.114.143.041.319.041.246 0 .413-.07a.56.56 0 0 0 .255-.193.5.5 0 0 0 .085-.29.39.39 0 0 0-.153-.326q-.152-.12-.462-.193l-.619-.143a1.7 1.7 0 0 1-.539-.214 1 1 0 0 1-.351-.367 1.1 1.1 0 0 1-.123-.524q0-.366.19-.639.19-.272.527-.422.338-.15.777-.149.457 0 .78.152.324.153.5.41.18.255.2.566h-.75a.56.56 0 0 0-.12-.258.6.6 0 0 0-.247-.181.9.9 0 0 0-.369-.068q-.325 0-.513.152a.47.47 0 0 0-.184.384q0 .18.143.3a1 1 0 0 0 .405.175l.62.143q.326.075.566.211a1 1 0 0 1 .375.358q.135.222.135.56 0 .37-.188.656a1.2 1.2 0 0 1-.539.439q-.351.158-.858.158-.381 0-.665-.09a1.4 1.4 0 0 1-.478-.252 1.1 1.1 0 0 1-.29-.375m-2.945-3.358h-.893L1.81 13.37h-.036l-.832-1.438h-.93l1.227 1.983L0 15.931h.861l.853-1.415h.035l.85 1.415h.908L2.253 13.94zm2.727 3.325H4.557v-3.325h-.79v4h2.487z"
              /></svg
          ></label>
          <a
            :title="$t('admin.excel.title.download_template')"
            class="btn ml-2 font-weight-bold btn-light-info"
            href="/excels/Template_Upload_Departments_Kymatio.xlsx"
            download
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-file-excel"
              viewBox="0 0 16 16"
            >
              <path
                d="M5.18 4.616a.5.5 0 0 1 .704.064L8 7.219l2.116-2.54a.5.5 0 1 1 .768.641L8.651 8l2.233 2.68a.5.5 0 0 1-.768.64L8 8.781l-2.116 2.54a.5.5 0 0 1-.768-.641L7.349 8 5.116 5.32a.5.5 0 0 1 .064-.704"
              />
              <path
                d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1"
              /></svg
          ></a>
        </div>
        <!--end::Toolbar-->
      </div>
    </div>
    <b-card
      class="card-custom card-stretch card-rounded"
      no-body
      v-if="areDepartmentsLoaded"
    >
      <b-card-body>
        <b-table
          id="departments-table"
          hover
          :items="filtered"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          primary-key="stakeholderId"
          :responsive="true"
          show-empty
          selectable
          @row-selected="onRowSelected"
          ref="selectableTable"
        >
          <template>
            <empty-search
              :description="$t(`tables.empty.description`)"
              :buttonText="$t(`tables.empty.button`)"
              :action="resetFilters"
            />
          </template>
          <template #cell(selected)="{ rowSelected }">
            <i v-if="rowSelected" class="fa fa-check" />
          </template>
          <template #cell(actions)="row">
            <b-button
              pill
              variant="light-primary"
              class="mr-2 px-auto"
              @click="updateDepartment(row.item.stakeholderId)"
            >
              <i class="la la-pencil pl-1" />
            </b-button>
            <b-button
              pill
              variant="light-danger"
              class="mr-2 px-auto"
              @click="deleteDepartment(row.item)"
            >
              <i class="la la-trash pl-1" />
            </b-button>
          </template>
        </b-table>
        <div class="flex-wrap mx-auto" v-if="total > 0">
          <b-pagination
            class="d-flex flex-wrap justify-content-center mt-3 py-2 mr-3"
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            aria-controls="departments-table"
            first-number
            pill
            last-number
            size="lg"
          ></b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import {
  deleteStakeholder,
  deleteStakeholders
} from "@/api/admin/stakeholders.api";

import EmptySearch from "@/components/EmptySearch.component.vue";
import JsonExcel from "vue-json-excel";
import { SimpleSVG } from "vue-simple-svg";
import readXlsxFile from "read-excel-file";

export default {
  name: "DepartmentsList",
  components: {
    EmptySearch,
    JsonExcel,
    "simple-svg": SimpleSVG
  },
  data() {
    return {
      perPage: 10,
      grid: true,
      currentPage: 1,
      search: "",
      byDepartment: false,
      searchDepartment: "",
      department: {},
      title: "",
      allSelected: false,
      selected: []
    };
  },
  computed: {
    ...mapGetters("Constants", ["isConstantsActive"]),
    ...mapGetters("Admin", ["areDepartmentsLoaded", "departments"]),
    ...mapGetters("Auth", ["hasModule"]),
    fields() {
      const fields = [
        {
          key: "selected",
          label: this.$tc("tables.selected", 1),
          sortable: false,
          class: "align-middle"
        },
        {
          key: "name",
          label: this.$tc("tables.name", 1),
          sortable: true,
          sortByFormatted: true,
          class: "align-middle text-left"
        },
        {
          key: "stakeholderDepartmentParentName",
          label: this.$tc("tables.stakeholderDepartmentParentName", 1),
          sortable: true,
          sortByFormatted: true,
          class: "align-middle text-left"
        },
        {
          key: "peopleCount",
          label: this.$tc("tables.employees", 1),
          sortable: true,
          sortByFormatted: true,
          class: "align-middle text-left"
        },
        {
          key: "actions",
          label: this.$t("tables.actions"),
          sortable: false,
          class: "align-middle"
        }
      ];
      return fields;
    },
    excelFields() {
      var fields = {};
      fields[this.$tc("tables.name", 1)] = {
        name: "name",
        callback: value => value.name
      };
      return fields;
    },
    total() {
      var departments = this.departments;
      if (this.search !== "") {
        const regex = new RegExp(`\\b${_.deburr(this.search)}`, "i");
        departments = _.filter(departments, department => {
          return (
            regex.test(_.deburr(department.name + " " + department.surname)) ||
            regex.test(_.deburr(department.stakeholderDepartmentName))
          );
        });
      }
      return _.size(departments);
    },
    filtered() {
      var departments = this.departments;
      if (this.search !== "") {
        const regex = new RegExp(`\\b${_.deburr(this.search)}`, "i");
        departments = _.filter(departments, department => {
          return (
            regex.test(_.deburr(department.name + " " + department.surname)) ||
            regex.test(_.deburr(department.stakeholderDepartmentName))
          );
        });
      }
      return this.grid ? _.slice(departments, 0, this.showing) : departments;
    },
    hasExcelPermissions() {
      const companyId = localStorage.getItem("companyId");
      const validCompanyIds = [1, 7673];
      return validCompanyIds.includes(Number(companyId));
    }
  },
  methods: {
    ...mapActions("Admin", [
      "loadCompanySelected",
      "loadDepartments",
      "loadUsers",
      "reset"
    ]),
    handleFileExcel(event) {
      const requiredColumnNames = [
        "Department",
        "Parent Department",
        "Position"
      ];
      let xlsxfile = event.target.files ? event.target.files[0] : null;
      readXlsxFile(xlsxfile, { sheet: "1.-Departamentos (internal)" })
        .then(rows => {
          const columnNames = rows[0];
          const missingHeaders = _.difference(requiredColumnNames, columnNames);
          if (missingHeaders.length > 0) {
            this.$swal({
              title: "Error",
              text: "Faltan encabezados: " + missingHeaders,
              icon: "error",
              button: "OK"
            }).then(() => {
              this.$refs.excelFile.value = null;
            });
          } else {
            const jsonData = rows.slice(1).map((row, index) => {
              const rowData = {};
              columnNames.forEach((columnName, index) => {
                rowData[columnName] = row[index];
              });
              rowData["rowId"] = index;
              return rowData;
            });
            this.$router.push({
              name: "DepartmentsExcel",
              query: { excelData: jsonData }
            });
          }
        })
        .catch(error => {
          this.$swal({
            title: "Error",
            text: error,
            icon: "error",
            button: "OK"
          }).then(() => {
            this.$refs.excelFile.value = null;
          });
        });
    },
    onRowSelected(items) {
      this.selected = items;
    },
    updateDepartment(stakeholderId) {
      this.$router.push({
        name: "DepartmentUpdate",
        query: { type: "single" },
        params: {
          id: stakeholderId
        }
      });
    },
    updateMultiDepartment() {
      let departments =
        this.selected.length == 0 ? this.filtered : this.selected;
      this.$router.push({
        name: "DepartmentUpdate",
        query: { type: "multiple" },
        params: {
          departments: _.map(departments, element => element.stakeholderId)
        }
      });
    },
    deleteMultiDepartment() {
      let departments =
        this.selected.length == 0 ? this.filtered : this.selected;

      let hasUsersOrDepartments = false;
      _.each(departments, department => {
        if (department.peopleCount > 0 || department.departmentsCount > 0) {
          hasUsersOrDepartments = true;
        }
      });

      this.$swal({
        html: hasUsersOrDepartments
          ? this.$t("admin.popup.delete.multi_department_with_data", {
              departments: departments.length,
              confirmation: this.$t("admin.popup.delete.delete_confirmation")
            })
          : this.$t("admin.popup.delete.multi_department", {
              departments: departments.length,
              confirmation: this.$t("admin.popup.delete.delete_confirmation")
            }),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0BB783",
        confirmButtonText: this.$t("admin.popup.confirmButton"),
        cancelButtonText: this.$t("admin.popup.cancelButton"),
        input: "text",
        inputValidator: value => {
          if (value === this.$t("admin.popup.delete.delete_confirmation")) {
            let stakeholdersId = _.map(
              departments,
              element => element.stakeholderId
            );
            deleteStakeholders("departments", stakeholdersId).then(() => {
              this.loadCompanySelected();
              this.loadDepartments();
              this.loadUsers();
            });
          } else {
            return this.$t("admin.popup.incorrectWord");
          }
        }
      });
    },
    deleteDepartment(stakeholder) {
      const departments = stakeholder.departmentsCount;
      const people = stakeholder.peopleCount;

      if (departments > 0 && people > 0) {
        this.title = this.$t(
          "admin.popup.delete.departmentWithUsersAndDepartmentsAdvice"
        );
      } else if (departments == 0 && people > 0) {
        this.title = this.$t("admin.popup.delete.departmentWithUsersAdvice");
      } else if (departments > 0 && people == 0) {
        this.title = this.$t(
          "admin.popup.delete.departmentWithDepartmentsAdvice"
        );
      } else {
        this.title = this.$t("admin.popup.delete.departmentAdvice");
      }

      this.$swal({
        title: this.title,
        html:
          this.$t("admin.popup.delete.department") +
          "<br>" +
          "<br>" +
          this.$t("admin.popup.delete.departmentConfirm"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0BB783",
        confirmButtonText: this.$t("admin.popup.confirmButton"),
        cancelButtonText: this.$t("admin.popup.cancelButton"),
        input: "text",
        inputLabel: "Your IP address",
        inputValidator: value => {
          if (value === this.$t("admin.popup.departmentInputText")) {
            deleteStakeholder("departments", stakeholder.stakeholderId).then(
              () => {
                this.loadUsers();
                this.loadDepartments();
                this.loadCompanySelected();
              }
            );
          } else {
            return this.$t("admin.popup.incorrectWord");
          }
        }
      });
    },
    resetFilters() {
      this.search = "";
    }
  },
  mounted() {
    if (this.isConstantsActive && !this.areDepartmentsLoaded)
      this.loadDepartments();
    if (this.$route.query.department) this.byDepartment = true;
  },
  watch: {
    isConstantsActive(value) {
      if (value === true) this.loadDepartments();
    },
    areDepartmentsLoaded(value) {
      if (this.isConstantsActive && value === false) this.loadDepartments();
    }
  }
};
</script>
